export const graphqlPath = "https://api.stagingbds.com";
export const subscriptionsPath = "wss://api.stagingbds.com";

const region = "us-east-2";
const userPoolId = "us-east-2_bQ1jALOQL";
const userPoolWebClientId = "31rgcmghak1uq43fcn6gqn5pom";
const authenticationFlowType = "USER_SRP_AUTH";

export const defaultAmpConfig = {
  region,
  userPoolId,
  userPoolWebClientId,
  authenticationFlowType,
};

export const adminConfig = {
  userPoolId: "us-east-2_U47Zrb1i8",
  userPoolWebClientId: "6ihtf8lq38mq3j910ntppjbdhd",
};
